import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Moment from 'react-moment'
import apiKeys from '../apikeys';
import { createClient } from 'contentful'
import BlogCard from "../components/Blog/BlogCard"
import Footer from "../components/Footer"
import Header from "../components/Header"
import './blogpost.scss'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// markup
const IndexPage = () => {

  const [headerColor, setHeaderColor] = useState('dark');
  const [selectedComponent, setSelectedComponent] = useState(5);
  const [document, setDocument] = useState();
  const [relatedDocs, setRelatedDocs] = useState([]);

  let options = {
    renderNode: {
      'embedded-asset-block': (node) =>
        <img class="img-fluid" src={`${node.data.target.fields.file.url}`}/>
    }
  }

  useEffect(() => {
    getBlogPost()
    getRelatedPosts()
  }, [])

  const getBlogPost = () => {
    const client = createClient({
      space: apiKeys.CONTENTFUL_SPACEID,
      environment: 'master',
      accessToken: apiKeys.CONTENTFUL_APIKEY,
    })

    client.getEntries({
      content_type: 'blogPost',
      'fields.slug': `/${window.location.pathname.split("/").pop()}`
    })
    .then((response) => {
      if (response.items.length > 0) return setDocument(response.items[0])
      window.location.pathname = "/404"
    })
    .catch(console.error)
  }

  const getRelatedPosts = () => {
    const client = createClient({
      space: apiKeys.CONTENTFUL_SPACEID,
      environment: 'master',
      accessToken: apiKeys.CONTENTFUL_APIKEY,
    })

    client.getEntries({
      limit: 3,
      content_type: 'blogPost',
      'fields.slug[nin]': `/${window.location.pathname.split("/").pop()}`
    })
    .then((response) => {
      setRelatedDocs(response.items)
    })
    .catch(console.error)
  }

  return (
    <main className={"site"}>      
      <Helmet title={document ? document.fields.metaTitle : 'Scorf'}>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name='title' content={document ? document.fields.metaTitle : 'Scorf'}/>
          <meta name="description" content={document ? document.fields.metaDescription : "Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes."} />
          <meta name="keywords" content={document ? document.fields.metaKeywords : ""} />
          <link rel="canonical" href="https://www.scorf.io/" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-8VFYNJHG71"></script>
          <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-8VFYNJHG71');`}}>            
          </script>
      </Helmet>
      <Header headerColor={headerColor} selectedComponent={selectedComponent}/>
      <div className="blogPostContainer container">
        {document && (
          <>
            <h1 className="title">{document.fields.title}</h1>
            <Moment format="DD-MM-YYYY">{document.sys.createdAt}</Moment>
            <img src={document.fields.image.fields.file.url} alt={document.fields.title} />
            <div className="content">
              {documentToReactComponents(document.fields.content, options)}
            </div>
          </>
        )}
      </div>
      <div className="relatedArticlesContainer container">
        {relatedDocs.map(doc => <BlogCard document={doc} />)}
      </div>
      <Footer setHeaderColor={setHeaderColor} setSelectedComponent={setSelectedComponent} />
    </main>
  )
}

export default IndexPage
